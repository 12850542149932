import * as _ from "lodash";

export const produce = (base, processFunction) => {
    if (typeof base === "function") {
        return (prevState) => {
            return updateState(base)(prevState);
        };
    } else {
        return updateValue(base, processFunction);
    }
};
export const updateValue = (base, processFunction) => {
    let newBase = _.cloneDeep(base);
    processFunction(newBase);
    return newBase;
};

export const updateState = (processFunction) => (prevState) => {
    let newBase = _.cloneDeep(prevState);
    processFunction(newBase);
    return newBase;
};
export const compare = (property, type) => {
    return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        if (type === "asc") {
            if (value1 < value2) {
                return -1;
            } else if (value1 > value2) {
                return 1;
            } else {
                return 0;
            }
        }
        if (type === "desc") {
            if (value1 < value2) {
                return 1;
            } else if (value1 > value2) {
                return -1;
            } else {
                return 0;
            }
        }
    };
};

export const compareDate = (type) => {
    return function (a, b) {
        const date1 = new Date(a).getTime();
        const date2 = new Date(b).getTime();
        return type === "desc" ? date2 - date1 : date1 - date2;
    };
};

export const isNotNumber = (value) => /([^0-9]+)/g.test(value);

export const getKgFromLbs = (lbs) => lbs * 0.4536;

export const getLbsFromKg = (kg) => kg / 0.4536;

export const getLbsValue = (num) => parseFloat(getLbsFromKg(num).toFixed(1));

export const areAllValuesNotEmpty = (obj) => {
    const values = Object.values(obj);
    const isEmpty = (value) => {
        if (value === null || value === undefined) {
            return true;
        }
        if (typeof value === "string" && value.trim() === "") {
            return true;
        }
        if (Array.isArray(value) && value.length === 0) {
            return true;
        }
        return false;
    };

    return values.every((value) => !isEmpty(value));
};

export const getSecureText = (str) => {
    return str ? str.replace(/.{3}$/, "***") : "";
    // return str ? str.slice(0, 5)+ str.slice(3).replace(/./g, '*')  : '';
};

export const cleanNullValueOfObj = (obj, keepEmptyString) => {
    let newObj = {};
    Object.entries(obj).forEach(([key, value]) => {
        if (keepEmptyString && value === "") {
            newObj[key] = value;
            return;
        }
        if (value !== "" && value !== null && value !== undefined) {
            newObj[key] = value;
        }
    });
    return newObj;
};

export const getPercentValue = (num, total) => {
    const percent = total > 0 ? Math.floor((num / total) * 100) : 0;
    return percent > 0 ? `${percent}%` : "0%";
};

export const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
        return true;
    }

    if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (!obj2.hasOwnProperty(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
};

export const getEnvironment = () => {
    const environment = process.env.REACT_APP_ENV;
    switch (environment) {
        case "UATINTERNET":
        case "UATINTRANET":
        case "UAT":
            return "/esign";
        case "TEST":
            return "";
        default:
            return "";
    }
};

export const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
    });
};

export const isFromBeforeTo = (from, to) => {
    const fromDate = new Date(from);
    const toDate = to ? new Date(to) : null;
    return `${formattedDate(from)}${toDate && fromDate < toDate ? ` - ${formattedDate(to)}` : ""}`;
};

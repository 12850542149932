import React, { useState, useEffect } from "react";
import styled from "styled-components";
import searchIcon from "../../assets/images/search.svg";
import CategorySelect from "./components/CategorySelect";
import { fetchApi } from "../../api";
import { Grid, InputBase, Typography } from "@mui/material";
import ShopCard from "../../components/Card/ShopCard";
import { useHistory, useLocation } from "react-router-dom";
import headerPng from "../../assets/images/header.png";
import sortSvg from "../../assets/images/chevronExpand.svg";
import { useDispatch } from "react-redux";
import CustomPopper from "./components/CustomPopper";

const ShopContainerDiv = styled.div``;
const ImageBackgroundDiv = styled.div`
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-size: cover; // 或其他背景尺寸属性
    height: calc(300px - 96px);
    position: relative;
    padding: 48px 100px;
    margin-top: 2px;
`;
const FilterDiv = styled.div`
    width: 45%;
    background-color: #a7945f;
    padding: 48px 48px 24px 48px;
    border-radius: 20px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    margin: auto;
    margin-top: 20px;
`;
const FilterItemDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;
const KeywordDiv = styled.div`
    background-color: #fff;
    padding: 10px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: solid 1px #858585;
`;
const ShopListDiv = styled.div`
    width: 100%;
    height: calc(100% - 200px);
    margin-top: 158px;
    padding: 48px 0;
`;
const SortItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
`;
const ItemListDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-left: calc(8% - 80px);
`;

const ItemDiv = styled.div`
    margin: 20px;
    width: 46%;
`;

let defaultQuery =
    "{ queryShopsContents { id, flatData { name, category { id, flatData { name } }, title, describe, location, openingHours, enquirues, email, imageText, createDateTime, image { url } } } }";

const Shop = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const filter = location.state?.filter;
    const [items, setItems] = useState([]);
    const [state, setState] = useState({
        keyword: "",
        categoryIds: filter || [],
        categoryList: [],
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [sortType, setSortType] = useState("Latest");

    useEffect(() => {
        if (filter) {
            handleFilter({ filterList: filter });
        } else {
            fetchShopsData();
        }
        fetchCategoriesData();
    }, [filter]);

    const fetchShopsData = async (query = defaultQuery) => {
        const result = await fetchApi({ query, dispatch });
        sortByProperty(result.queryShopsContents, sortType);
    };

    const fetchCategoriesData = async () => {
        const query = `{ queryCategoriesContents { id, flatData { name } } }`;
        const result = await fetchApi({ query, dispatch });
        setState((prevState) => ({
            ...prevState,
            categoryList: result.queryCategoriesContents,
        }));
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let categoryIds = typeof value === "string" ? value.split(",") : value;
        setState((prevState) => ({ ...prevState, categoryIds }));
        handleFilter({ filterList: categoryIds });
    };

    const handleFilter = ({ filterList = state.categoryIds }) => {
        let query = defaultQuery;
        let filterParams = [];
        if (state.keyword && filterList.length) {
            filterParams = `(${filterList.map((item) => `'${item}'`).join(",")})`;
            query = `{ queryShopsContents( filter: "contains(data/Name/iv, '${encodeURIComponent(
                state.keyword,
            )}') and data/Category/iv in ${filterParams}" )  { id, flatData { name, category { id, flatData { name } }, title, describe, location, openingHours, enquirues, email, image { url } } } }`;
        }
        if (!state.keyword && filterList.length) {
            filterParams = `(${filterList.map((item) => `'${item}'`).join(",")})`;
            query = `{ queryShopsContents( filter: "data/Category/iv in ${filterParams}" )  { id, flatData { name, category { id, flatData { name } }, title, describe, location, openingHours, enquirues, email, image { url } } } }`;
        }
        if (state.keyword && !filterList.length) {
            query = `{ queryShopsContents ( filter: "contains(data/Name/iv, '${encodeURIComponent(
                state.keyword,
            )}')" ) { id, flatData { name,  category { id, flatData { name } }, title, describe, location, openingHours, enquirues, email, image { url } } } }`;
        }
        fetchShopsData(query);
    };

    const sortByProperty = (data, order) => {
        let sortedItems;
        if (order === "Ascending Order (A - Z)") {
            sortedItems = [...data].sort((a, b) =>
                a.flatData.name.toLowerCase().localeCompare(b.flatData.name.toLowerCase()),
            );
        } else if (order === "Descending Order (Z - A)") {
            sortedItems = [...data].sort((a, b) =>
                b.flatData.name.toLowerCase().localeCompare(a.flatData.name.toLowerCase()),
            );
        } else {
            sortedItems = sortByCreateTime(data);
        }
        setItems(sortedItems); // 更新状态
    };

    const sortByCreateTime = (data) => {
        return data.sort(
            (a, b) => new Date(b.flatData.createDateTime) - new Date(a.flatData.createDateTime),
        );
    };

    const handlePopperClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handleSortType = (newSortType) => (event) => {
        setSortType(newSortType);
        sortByProperty(items, newSortType);
        setOpen(false);
    };

    return (
        <ShopContainerDiv>
            <ImageBackgroundDiv imageurl={headerPng}>
                <Typography color="#a7945f">Explore & Discover</Typography>
                <Typography color="#151515" fontSize={40} fontFamily={"Prata-Regular"}>
                    Discover our Shops
                </Typography>
                <Typography color="#151515">
                    Renowned brands from all over the world are brought under one roof.
                </Typography>
                <FilterDiv>
                    <FilterItemDiv>
                        <Typography color={"#f5f5f5"} mb={"8px"}>
                            Keyword
                        </Typography>
                        <KeywordDiv>
                            <InputBase
                                placeholder="Search"
                                inputProps={{ "aria-label": "Search" }}
                                sx={{ color: "#151515", fontSize: 14 }}
                                onChange={(e) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        keyword: e.target.value,
                                    }))
                                }
                            />
                            <img
                                src={searchIcon}
                                onClick={handleFilter}
                                style={{ cursor: "pointer" }}
                            />
                        </KeywordDiv>
                    </FilterItemDiv>
                    <FilterItemDiv>
                        <Typography color={"#f5f5f5"} mb={"8px"}>
                            Category
                        </Typography>
                        <CategorySelect
                            handleChange={handleChange}
                            categoryList={state.categoryList}
                            categoryIds={state.categoryIds}
                            filter={filter}
                        />
                    </FilterItemDiv>
                    <SortItem onClick={handlePopperClick("bottom-end")}>
                        <img src={sortSvg} />
                        <Typography color={"#f5f5f5"}>{`Sort by ${sortType}`}</Typography>
                    </SortItem>

                    <CustomPopper
                        placement={placement}
                        open={open}
                        anchorEl={anchorEl}
                        sortType={sortType}
                        handleClick={handleSortType}
                    />
                </FilterDiv>
            </ImageBackgroundDiv>
            <ShopListDiv>
                <ItemListDiv>
                    {items.length > 0 &&
                        items.map((item) => (
                            <ItemDiv key={item.id}>
                                <ShopCard
                                    item={item}
                                    handleClick={() =>
                                        history.push("/shop/shopDetail", {
                                            name: item.flatData.name,
                                        })
                                    }
                                />
                            </ItemDiv>
                        ))}
                </ItemListDiv>
            </ShopListDiv>
        </ShopContainerDiv>
    );
};
export default Shop;

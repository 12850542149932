import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { fetchApi } from "../../api";
import { Grid, Typography } from "@mui/material";
import InfoCard from "../../components/Card/InfoCard";
import mapMarkerAltIcon from "../../assets/images/mapMarkerAlt.svg";
import clockIcon from "../../assets/images/clock.svg";
import phoneIcon from "../../assets/images/phone.svg";
import emailIcon from "../../assets/images/emailGloden.svg";
import Recommandation from "../../components/Recommandation";
import ShopCard from "../../components/Card/ShopCard";
import { useDispatch } from "react-redux";

const ImageBackgroundDiv = styled.div`
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-size: cover; // 或其他背景尺寸属性
    height: 600px;
`;
const ImageBackgroundContentDiv = styled.div`
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: flex-end;
`;
const Container = styled.div`
    background-color: #f3f3f3;
`;
const DetailDiv = styled.div`
    background-color: #fff;
    padding: 48px 100px;
`;

const ShopDetail = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const name = location.state.name;
    const [detail, setDetail] = useState({});
    const [info, setInfo] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // 定义一个函数来更新 windowWidth 的值
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // 在组件挂载时添加 resize 事件监听器
        window.addEventListener("resize", handleResize);

        // 在组件卸载时移除 resize 事件监听器
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // 空数组作为依赖项，表示只在组件挂载时执行一次

    useEffect(() => {
        fetchData();
    }, [name]);

    const fetchData = async () => {
        const query = `{ queryShopsContents (filter: "data/Name/iv eq '${encodeURIComponent(
            name,
        )}'") { id, flatData { name,  category { id, flatData { name } }, title, describe, location, openingHours, enquirues, email, imageText, image { url } } } }`;
        const result = await fetchApi({ query, dispatch });
        setDetail(result.queryShopsContents[0].flatData);
        setInfo([
            {
                imageUrl: mapMarkerAltIcon,
                title: "Location",
                value: result.queryShopsContents[0].flatData.location,
            },
            {
                imageUrl: clockIcon,
                title: "Opening Hours",
                value: result.queryShopsContents[0].flatData.openingHours,
            },
            {
                imageUrl: phoneIcon,
                title: "Enquirues",
                value: result.queryShopsContents[0].flatData.enquirues,
            },
            {
                imageUrl: emailIcon,
                title: "Email",
                value: result.queryShopsContents[0].flatData.email,
            },
        ]);
    };
    console.log(detail);

    return (
        name && (
            <Container>
                <ImageBackgroundDiv imageurl={detail.image && detail.image[0].url}>
                    <ImageBackgroundContentDiv>
                        <Typography
                            color={"#fff"}
                            fontFamily={"Roboto-Bold"}
                            fontSize={60}
                            ml={5}
                            mb={2}
                        >
                            {detail.imageText}
                        </Typography>
                    </ImageBackgroundContentDiv>
                </ImageBackgroundDiv>
                <DetailDiv>
                    <Grid container justifyContent="space-around">
                        <Grid item xs={3}>
                            <InfoCard renderInfoItem={info} />
                        </Grid>
                        <Grid item xs={7}>
                            <Typography color={"#a7945f"} fontSize={20} fontFamily={"Roboto-Bold"}>
                                {(detail.category && detail.category[0].flatData.name) || "-"}
                            </Typography>
                            <Typography fontSize={48} fontFamily={"Roboto-Bold"}>
                                {detail.name || "-"}
                            </Typography>
                            <Typography>{detail.describe || "-"}</Typography>
                        </Grid>
                    </Grid>
                </DetailDiv>
                <Recommandation
                    isShowTitle
                    title={"Shops that you may also like..."}
                    query={
                        "{ queryShopsContents { id, flatData { name, category { id, flatData { name } }, title, describe, location, openingHours, enquirues, email, imageText, image { url } } } }"
                    }
                    queryName={"queryShopsContents"}
                    showCardComponent={(item) => (
                        <ShopCard
                            item={item}
                            handleClick={() => {
                                history.push("/shop/shopDetail", { name: item.flatData.name });
                            }}
                            shopCardStyle={{ marginLeft: 100 }}
                        />
                    )}
                    handleClick={() => history.push("/shop")}
                    slidesPerView={windowWidth > 1445 ? 3 : 2.5}
                    spaceBetween={24}
                />
            </Container>
        )
    );
};
export default ShopDetail;

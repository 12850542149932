import React from "react";
import styled from "styled-components";
import MainRouter from "./MainRouter";
import Header from "../components/Header";
import Footer from "../components/Footer/index";
const Container = styled.div`
    display: flex;
    background-color: #fff;
    flex-direction: column;
    min-width: 1440px;
`;
const Main = () => {
    return (
        <Container>
            <Header />
            <MainRouter />
            <Footer />
        </Container>
    );
};

export default Main;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import facebookSvg from "../../assets/images/facebook.svg";
import instagramSvg from "../../assets/images/instagram.svg";
import emailSvg from "../../assets/images/email.svg";
import { fetchApi } from "../../api";
import { useDispatch } from "react-redux";

const Container = styled.div`
    width: 100%;
`;
const FooterDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #a7945f;
    padding: 48px 96px;
`;
const FooterCardsDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`;
const FooterCardDiv = styled.div`
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
const FooterIconsDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 11%;
    justify-content: space-between;
    margin-top: 48px;
`;
const FooterBottomDiv = styled.div`
    background-color: #313131;
    padding: 24px 96px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
const Footer = () => {
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const query = "{ queryFooterContents { id, flatData { title, content } } }";
        const result = await fetchApi({ query, dispatch });
        setItems(result.queryFooterContents.reverse());
    };

    const Item = ({ footer }) => {
        const contentArray = footer.flatData.content.split("\n");

        return (
            <FooterCardDiv>
                <Typography fontSize={18} color="#fff" fontFamily={"Roboto-Medium"}>
                    {footer.flatData.title}
                </Typography>
                {contentArray.map((content, index) => (
                    <Typography key={index} fontSize={14} color="#fff">
                        {content}
                    </Typography>
                ))}
            </FooterCardDiv>
        );
    };

    return (
        <Container>
            <FooterDiv>
                <FooterCardsDiv>
                    {items?.length > 0 &&
                        items.map((footer, index) => <Item key={index} footer={footer} />)}
                </FooterCardsDiv>
                <FooterIconsDiv>
                    <img src={facebookSvg} />
                    <img src={instagramSvg} />
                    <img src={emailSvg} />
                </FooterIconsDiv>
            </FooterDiv>
            <FooterBottomDiv>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography fontSize={14} color={"#fff"}>
                        Terms of Use
                    </Typography>
                    <Typography fontSize={14} color={"#fff"} marginLeft="24px">
                        Privacy Policy
                    </Typography>
                </div>
                <Typography fontSize={14} color={"#fff"}>
                    COPYRIGHT © 2024 Demo Retail
                </Typography>
            </FooterBottomDiv>
        </Container>
    );
};
export default Footer;

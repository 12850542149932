import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import eyeSlashIcon from "../../../assets/images/eyeSlash.svg";
import gearFillIcon from "../../../assets/images/gearFill.svg";
import bookIcon from "../../../assets/images/book.svg";
import stickiesIcon from "../../../assets/images/stickies.svg";
import eyeSlashWhiteIcon from "../../../assets/images/eyeSlashWhite.svg";
import gearFillWhiteIcon from "../../../assets/images/gearFillWhite.svg";
import bookWhiteIcon from "../../../assets/images/bookWhite.svg";
import stickiesWhiteIcon from "../../../assets/images/stickiesWhite.svg";

// 定义styled-components
const Card = styled.div`
    background-color: #fff;
    border-radius: 10px;
`;

const Button = styled.button`
    width: 100%;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    color: #858585;
    background-color: #fff;
    background-color: ${(props) => props.isActive && "#a7945f"};
    color: ${(props) => props.isActive && "#fff"};
`;

const UserInfoDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
`;

const LeftNav = ({ state, navName, setNavName }) => {
    const formattedDateTime = (dateTime) => {
        return `Since ${new Date(dateTime).getFullYear()}`;
    };

    const NavList = [
        {
            imageUrl: stickiesIcon,
            imageUrlActive: stickiesWhiteIcon,
            title: "Personal Information",
        },
        { imageUrl: gearFillIcon, imageUrlActive: gearFillWhiteIcon, title: "Preference" },
        { imageUrl: eyeSlashIcon, imageUrlActive: eyeSlashWhiteIcon, title: "Privacy Setting" },
        { imageUrl: bookIcon, imageUrlActive: bookWhiteIcon, title: "Terms & Condition" },
    ];

    const ListItem = ({ item }) => {
        let imageUrl = navName === item.title ? item.imageUrlActive : item.imageUrl;
        return (
            <Button isActive={navName === item.title} onClick={() => setNavName(item.title)}>
                <img src={imageUrl} />
                <Typography fontSize={18} fontFamily={"Roboto-Medium"} ml={"16px"}>
                    {item.title}
                </Typography>
            </Button>
        );
    };

    return (
        <Card>
            <UserInfoDiv>
                <img src={state.avatar && state.avatar[0].url} />
                <div style={{ marginLeft: 48 }}>
                    <Typography color={"#858585"} fontSize={14}>
                        {formattedDateTime(state.createDateTime)}
                    </Typography>
                    <Typography fontSize={20} fontFamily={"Roboto-Bold"}>
                        {`${state.firstName} ${state.lastName}`}
                    </Typography>
                    <Typography color={"#a7945f"} fontSize={14}>
                        {state.member}
                    </Typography>
                </div>
            </UserInfoDiv>
            <div>
                {NavList.map((item, index) => (
                    <ListItem key={index} item={item} />
                ))}
            </div>
        </Card>
    );
};

export default LeftNav;

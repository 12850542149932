import { produce } from "../../utility/utils";
import { SET_GLOBAL } from "../actionTypes";

const initialState = {
    isLoading: true,
};

const globalReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case SET_GLOBAL:
            newState = produce(state, (draft) => {
                Object.entries(action.payload).forEach(([key, value]) => {
                    draft[key] = action.payload[key];
                });
            });
            return newState;
        default:
            return state;
    }
};

export default globalReducer;

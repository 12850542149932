import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { batchedSubscribe } from "redux-batched-subscribe";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import globalReducer from "./reducers/global";
import thunk from "redux-thunk";
import _ from "lodash";

const debounceNotify = _.debounce((notify) => notify());

const reducers = combineReducers({
    global: globalReducer,
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["global"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
    devTools: true,
    preloadedState: {},
    // enhancers: [batchedSubscribe(debounceNotify)],
});

export const persistor = persistStore(store);

export const resetStore = async () => {
    await persistor.purge();
    console.log("Factory reset performed.");
};

export default store;

import React from 'react';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
const LandingSectionContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    background: #000;
    z-index: 99998;
    opacity: 0.5;
    height: 100vh;
    color: #fff;
    align-items: center;
    justify-content: center;
`;
const LandingContainer = styled.img`
    position: fixed;
    z-index: 100;
    width: 200px;
    height: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    border-radius: 10px;
    z-index: 99999;
`;

const LandingSection = ({ children }) => {
    return (
        <>
            <LandingSectionContainer></LandingSectionContainer>
            <LandingContainer src={Loading}></LandingContainer>
        </>
    );
};

export default LandingSection;

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { getCombineRoute } from "../utility/route";
import styled from "styled-components";
import backgoundPng from "../assets/images/background.png";

const Container = styled.div`
    min-height: calc(100vh - 500px);
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-size: cover; // 或其他背景尺寸属性
    background-repeat: repeat-y;
    background-color: #f3f3f3;
`;
const MainRouter = () => {
    const combineRoute = getCombineRoute();

    return (
        <Container imageurl={backgoundPng}>
            <Switch>
                {true &&
                    combineRoute.map((route, index) => {
                        const Content = route.component;

                        let path = route.path;
                        // case when sub routing exist
                        if (route.subRoute?.length >= 1) {
                            return route.subRoute.map((subRoute) => {
                                const SubRouteComponent = subRoute.component;

                                return (
                                    <Route
                                        key={`${path}-${subRoute.path}`}
                                        path={`${path}${subRoute.path}`}
                                        exact
                                    >
                                        <SubRouteComponent name={subRoute.name} />
                                    </Route>
                                );
                            });
                        }

                        // case without sub routing
                        return (
                            <Route
                                key={`${index}-${route.path}`}
                                path={route.path}
                                exact={route.exact}
                            >
                                <Content />
                            </Route>
                        );
                    })}
                {/* {!isLogin && <Redirect to={{ pathname: '/login' }} />} */}
            </Switch>
        </Container>
    );
};

export default MainRouter;

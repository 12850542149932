import React, { useEffect, useState } from "react";
import styled from "styled-components";
import headerPng from "../../assets/images/header.png";
import { Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchApi } from "../../api";
import LeftNav from "./components/LeftNav";
import PersonalInformation from "./components/PersonalInformation";
import Preference from "./components/Preference";
import PrivacySetting from "./components/PrivacySetting";
import TermsCondition from "./components/TermsCondition";

const Container = styled.div``;
const ImageBackgroundDiv = styled.div`
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-size: cover; // 或其他背景尺寸属性
    padding: 48px 100px;
    margin-top: 2px;
`;
const Content = styled.div`
    padding: 48px 100px;
`;

const Profile = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState({});
    const [navName, setNavName] = useState("Personal Information");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const query =
            "{ queryProfileContents { id, flatData { member, firstName, lastName, phoneNumber, email, describe, createDateTime, avatar { url } } } }";
        const result = await fetchApi({ query, dispatch });
        if (!result.queryProfileContents) return;
        setState(result.queryProfileContents[0].flatData);
    };

    const renderContentByNav = () => {
        switch (navName) {
            case "Personal Information":
                return <PersonalInformation state={state} />;
            case "Preference":
                return <Preference state={state} />;
            case "Privacy Setting":
                return <PrivacySetting state={state} />;
            case "Terms & Condition":
                return <TermsCondition state={state} />;
            default:
                break;
        }
    };

    return (
        <Container>
            <ImageBackgroundDiv imageurl={headerPng}>
                <Typography color="#151515" fontSize={40} fontFamily={"Prata-Regular"}>
                    PROFILE
                </Typography>
            </ImageBackgroundDiv>
            <Content>
                <Grid container justifyContent={"space-between"} alignItems={"flex-start"}>
                    <Grid item xs={3}>
                        <LeftNav state={state} navName={navName} setNavName={setNavName} />
                    </Grid>
                    <Grid item xs={8}>
                        {renderContentByNav()}
                    </Grid>
                </Grid>
            </Content>
        </Container>
    );
};
export default Profile;

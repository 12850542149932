import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { fetchApi } from "../../api";
import { ButtonBase, Grid, Typography } from "@mui/material";
import arrowLeftIcon from "../../assets/images/arrowleft.svg";
import dateIcon from "../../assets/images/calendarAlt.svg";
import listUIIcon from "../../assets/images/listUI.svg";
import { isFromBeforeTo } from "../../utility/utils";
import EventCard from "../../components/Card/EventCard";
import Recommandation from "../../components/Recommandation";
import PromoCard from "../../components/Card/PromoCard";
import { useDispatch } from "react-redux";

const ImageBackgroundDiv = styled.div`
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-size: cover; // 或其他背景尺寸属性
    height: 600px;
`;
const ImageBackgroundContentDiv = styled.div`
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: flex-end;
`;
const Container = styled.div``;
const DetailDiv = styled.div`
    background-color: #fff;
    padding: 48px 100px;
`;
const BackBtn = styled(ButtonBase)`
    && {
        text-transform: none;
        text-align: left;
    }
`;
const LabelItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const PromoDetail = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const name = location.state.name;
    const [detail, setDetail] = useState({});

    useEffect(() => {
        fetchData();
    }, [name]);

    const fetchData = async () => {
        const query = `{ queryPromotionsContents (filter: "data/Name/iv eq '${encodeURIComponent(
            name,
        )}'") { id, flatData { name, type { id, flatData { name } }, describe, applicable { id, flatData { name } }, from, to, logo { url }, image { url }, imageText } } }`;
        const result = (await fetchApi({ query, dispatch })) || {};
        setDetail(result.queryPromotionsContents[0].flatData);
    };

    return (
        <Container>
            <ImageBackgroundDiv imageurl={detail.image && detail.image[0].url}>
                <ImageBackgroundContentDiv>
                    <Typography
                        color={"#fff"}
                        fontFamily={"Roboto-Bold"}
                        fontSize={60}
                        ml={5}
                        mb={2}
                    >
                        {detail.imageText}
                    </Typography>
                </ImageBackgroundContentDiv>
            </ImageBackgroundDiv>
            <DetailDiv>
                <Grid container>
                    <Grid item>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                width: 80,
                                height: 80,
                                border: "2px solid #858585",
                                borderRadius: 5,
                                marginRight: 24,
                            }}
                        >
                            <img src={detail.logo && detail.logo[0].url} style={{ width: 80 }} />
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography color="#a7945f" fontSize={20} fontFamily={"Roboto-Bold"}>
                            {detail.applicable ? detail.applicable[0].flatData.name : "-"}
                        </Typography>
                        <Typography fontSize={48} fontFamily={"Roboto-Bold"} mb={"36px"}>
                            {detail.name || "-"}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container mb={"36px"}>
                    <Grid item xs={6}>
                        <LabelItem>
                            <img src={listUIIcon} />
                            <div>
                                <Typography color={"#a7945f"} fontSize={14}>
                                    Applicable
                                </Typography>
                                <Typography color={"#858585"} fontSize={12}>
                                    {detail.applicable ? detail.applicable[0].flatData.name : "-"}
                                </Typography>
                            </div>
                        </LabelItem>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelItem>
                            <img src={dateIcon} />
                            <div>
                                <Typography color={"#a7945f"} fontSize={14}>
                                    Date
                                </Typography>
                                <Typography color={"#858585"} fontSize={12}>
                                    {isFromBeforeTo(detail.from, detail.to)}
                                </Typography>
                            </div>
                        </LabelItem>
                    </Grid>
                </Grid>
                <Typography>{detail.describe}</Typography>
            </DetailDiv>
            <Recommandation
                isShowTitle
                title={"Promotions that you may also like..."}
                query={
                    "{ queryPromotionsContents { id, flatData { name, type { id, flatData { name } }, describe, applicable { id, flatData { name } }, from, to, logo { url }, image { url }, imageText } } }"
                }
                queryName={"queryPromotionsContents"}
                showCardComponent={(item) => (
                    <PromoCard
                        item={item}
                        handleClick={() => {
                            history.push("/promotion/promotionDetail", {
                                name: item.flatData.name,
                            });
                        }}
                        promoStyle={{ marginLeft: 100, width: "100%" }}
                    />
                )}
                handleClick={() => history.push("/promotion")}
                slidesPerView={4}
                spaceBetween={72}
            />
        </Container>
    );
};
export default PromoDetail;

import React, { useState, useEffect } from "react";
// import required modules
import styled from "styled-components";
import { Typography } from "@mui/material";
import locationIcon from "../../assets/images/mapMarkerAlt.svg";
import clockIcon from "../../assets/images/clock.svg";
import arrowRightGoldenIcon from "../../assets/images/arrowRight_Golden.svg";

const SwiperCardDiv = styled.div`
    width: 100%;
    height: 200px;
    min-width: 380px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
`;
const SwiperCardContentDiv = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 24px;
    border-radius: 0 20px 20px 0;
    position: relative;
`;
const StatisticCardNext = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: 1px solid #a7945f;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    bottom: 15px;
`;

const ShopCard = ({ item, imageStyle, handleClick, shopCardStyle }) => {
    return (
        <SwiperCardDiv onClick={handleClick} style={shopCardStyle}>
            <div style={{ width: "200px", height: "200px" }}>
                <img
                    src={item.flatData.image[0].url}
                    style={{
                        borderRadius: "20px 0 0 20px",
                        width: "200px",
                        height: "100%",
                        objectFit: "cover",
                        ...imageStyle,
                    }}
                />
            </div>
            <SwiperCardContentDiv>
                <Typography color="#858585" fontSize={18} fontFamily={"Roboto-Medium"}>
                    {item.flatData.category[0].flatData.name}
                </Typography>
                <Typography color="#151515" fontSize={20} fontFamily={"Roboto-Bold"} mb={"12px"}>
                    {item.flatData.name}
                </Typography>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: 12 }}>
                    <img src={locationIcon} />
                    <Typography color="#a7945f" fontSize={14}>
                        {item.flatData.location || "-"}
                    </Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                    }}
                >
                    <img src={clockIcon} />
                    <div style={{ width: "60%" }}>
                        <Typography color="#a7945f" fontSize={14}>
                            Opening Hours
                        </Typography>
                        <Typography color="#858585" fontSize={12}>
                            {item.flatData.openingHours || "-"}
                        </Typography>
                    </div>
                </div>
                <StatisticCardNext>
                    <img src={arrowRightGoldenIcon} />
                </StatisticCardNext>
            </SwiperCardContentDiv>
        </SwiperCardDiv>
    );
};
export default ShopCard;

import React from "react";
import { isFromBeforeTo } from "../../utility/utils";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const PromotionCardDiv = styled.div`
    /* width: 100%; */
    height: 215px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 24px;
    border-radius: 10px;
    border-style: solid;
    border-width: 0;
    border-top-width: 10px;
    border-image-source: linear-gradient(91deg, #857549, #d2c9ae 98%);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #fff, #fff),
        linear-gradient(91deg, #857549, #d2c9ae 98%);
    margin-bottom: 12px;
    cursor: pointer;
`;
const PromoCard = ({ item, promoStyle, handleClick }) => {
    return (
        <PromotionCardDiv key={item.id} style={promoStyle} onClick={handleClick}>
            <div>
                <img src={item.flatData.logo && item.flatData.logo[0].url} style={{ height: 64 }} />
                <Typography fontFamily={"Roboto-Bold"} fontSize={20}>
                    {item.flatData.name}
                </Typography>
            </div>
            <div>
                <Typography>
                    {item.flatData.applicable && item.flatData.applicable[0].flatData.name}
                </Typography>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: 10,
                    }}
                >
                    <Typography fontSize={12} color={"#a7945f"}>
                        {item.flatData.type && item.flatData.type[0].flatData.name}
                    </Typography>
                    <Typography fontSize={12} color={"#151515"}>
                        {isFromBeforeTo(item.flatData.from, item.flatData.to)}
                    </Typography>
                </div>
            </div>
        </PromotionCardDiv>
    );
};
export default PromoCard;

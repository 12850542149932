import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Main from "./pages";
import "./App.css";
import LandingSection from "./components/LandingSection";
import { useSelector } from "react-redux";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    React.useEffect(() => {
        // 每次路由变化时将页面滚动到顶部
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

function App() {
    const isLoading = useSelector((state) => state.global.isLoading);
    return (
        <>
            {isLoading && <LandingSection />}
            <ScrollToTop />
            <Switch>
                <Route path="/" component={Main} />
            </Switch>
        </>
    );
}

export default App;

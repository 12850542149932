import { SET_GLOBAL } from "../redux-thunk/actionTypes";

export const fetchApi = async ({ query, variables = {}, dispatch }) => {
    dispatch({
        type: SET_GLOBAL,
        payload: { isLoading: true },
    });
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const graphql = JSON.stringify({
        query,
        variables,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: graphql,
    };

    try {
        const response = await fetch(
            "https://cms-dev.485832a7f3534fffa2e5.eastasia.aksapp.io/api/content/headless-cms-demo/graphql",
            requestOptions,
        );
        // 响应拦截器
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        if (result.data) {
            dispatch({
                type: SET_GLOBAL,
                payload: { isLoading: false },
            });
            return result.data;
        }
    } catch (error) {
        // 处理错误，例如显示错误信息或执行其他逻辑
        console.error("Error fetching shop details:", error);
        throw error;
    }
};

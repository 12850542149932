import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { fetchApi } from "../../api";
import { useHistory } from "react-router-dom";
import PromoCard from "../../components/Card/PromoCard";
import headerPng from "../../assets/images/header.png";
import { useDispatch } from "react-redux";

const ContainerDiv = styled.div``;

const ImageBackgroundDiv = styled.div`
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-size: cover; // 或其他背景尺寸属性
    padding: 48px 100px;
    margin-top: 2px;
`;
const PromoListDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 28px;
    margin-bottom: 28px;
`;
const ItemListDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-left: calc(8% - 80px);
`;

const ItemDiv = styled.div`
    margin: 20px;
    width: 46%;
`;

const Promotion = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const query =
            "{ queryPromotionsContents { id, flatData { name, type { id, flatData { name } }, describe, applicable { id, flatData { name } }, from, to, logo { url }, image { url }, imageText } } }";
        const result = await fetchApi({ query, dispatch });
        setItems(result.queryPromotionsContents);
    };

    return (
        <ContainerDiv>
            <ImageBackgroundDiv imageurl={headerPng}>
                <Typography color="#a7945f">Shop, Save, and Celebrate at Retail Demo</Typography>
                <Typography color="#151515" fontSize={40} fontFamily={"Prata-Regular"}>
                    All the Best Promotions Under One Roof
                </Typography>
                <Typography color="#151515">
                    Discover exclusive discounts, rewards, and promotions from all your favorite
                    shops at Retail Demo.
                </Typography>
            </ImageBackgroundDiv>
            <PromoListDiv>
                <ItemListDiv>
                    {items.length > 0 &&
                        items.map((item) => (
                            <ItemDiv key={item.id}>
                                <PromoCard
                                    item={item}
                                    handleClick={() =>
                                        history.push("/promotion/promotionDetail", {
                                            name: item.flatData.name,
                                        })
                                    }
                                    promoStyle={{ marginBottom: 0 }}
                                />
                            </ItemDiv>
                        ))}
                </ItemListDiv>
            </PromoListDiv>
        </ContainerDiv>
    );
};
export default Promotion;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import { ButtonBase, Typography } from "@mui/material";
import {  isFromBeforeTo } from "../../../utility/utils";
import "./paginationBulletStyle.css";
import { useHistory } from "react-router-dom";
import { fetchApi } from "../../../api";
import CustomNavigationButton from "../../../components/CustomNavigationButton";
import { useDispatch } from "react-redux";

const ImageBackgroundDiv = styled.div`
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-size: cover; // 或其他背景尺寸属性
    height: 600px;
    width: 90%;
    margin: 0 auto;
`;
const SwiperContent = styled.div`
    background-image: linear-gradient(
        to bottom,
        rgba(34, 34, 34, 0),
        rgba(34, 34, 34, 0),
        rgba(34, 34, 34, 0.5),
        #222
    );
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    padding-left: 48px;
`;
const KnowMoreBtn = styled(ButtonBase)`
    && {
        background-color: #fff;
        border-radius: 10px;
        padding: 8px 24px;
        text-transform: none;
        width: 13%;
        margin-bottom: 100px;
    }
`;
const CustomSwiper = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [items, setItems] = useState([]);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const query =
            "{ queryEventsContents { id, flatData { name, location, describe, from, to, imageText, logo { url }, image { url } } } }";
        const result = await fetchApi({ query, dispatch });
        setItems(result.queryEventsContents.reverse());
    };

    const SwiperItem = ({ slide }) => {
        return (
            <ImageBackgroundDiv imageurl={slide.flatData.image[0].url} style={{ borderRadius: 20 }}>
                <SwiperContent style={{ borderRadius: 20 }}>
                    <Typography fontSize={20} color="#f5f5f5" fontFamily={"Roboto-Bold"}>
                        {isFromBeforeTo(slide.flatData.from, slide.flatData.to)}
                    </Typography>
                    <Typography fontSize={48} color="#f5f5f5" fontFamily={"Roboto-Bold"}>
                        {slide.flatData.name}
                    </Typography>
                    <KnowMoreBtn
                        variant="contained"
                        onClick={() =>
                            history.push("/event/eventDetail", { name: slide.flatData.name })
                        }
                    >
                        <Typography color="#a7945f">Know more</Typography>
                    </KnowMoreBtn>
                </SwiperContent>
            </ImageBackgroundDiv>
        );
    };

    return (
        <Swiper
            pagination={{
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return `<div class="${className} custom-bullet"></div>`;
                },
            }}
            navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
                disabledClass: "disable", // 当导航
            }}
            modules={[Pagination, Navigation]}
        >
            {items.map((slide) => (
                <SwiperSlide key={slide.id}>
                    <SwiperItem slide={slide} />
                </SwiperSlide>
            ))}
            <div
                className="swiper-pagination"
                style={{
                    textAlign: "left",
                    position: "absolute",
                    left: `calc(5% + 48px)`,
                    bottom: 48,
                }}
            />
            <div className="swiper-button-prev">
                <CustomNavigationButton direction="prev" />
            </div>
            <div className="swiper-button-next">
                <CustomNavigationButton direction="next" />
            </div>
        </Swiper>
    );
};

export default CustomSwiper;

import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { isFromBeforeTo } from "../../utility/utils";
import locationIcon from "../../assets/images/mapMarkerAlt.svg";

const Container = styled.div`
    background-color: #fff;
    border-radius: 20px;
    height: 400px;
    min-width: 320px;
    cursor: pointer;
    width: 100%;
`;
const ContentDiv = styled.div`
    padding: 24px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 248px);
`;

const EventCard = ({ item, imageStyle, handleClick, eventCardStyle }) => {
    return (
        item.flatData && (
            <Container onClick={handleClick} style={eventCardStyle}>
                <img
                    src={item.flatData.image[0].url}
                    style={{
                        borderRadius: "20px 20px 0 0",
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                        ...imageStyle,
                    }}
                />
                <ContentDiv>
                    <div>
                        <Typography mb={"12px"} color={"#858585"} fontSize={12}>
                            {isFromBeforeTo(item.flatData.from, item.flatData.to)}
                        </Typography>
                        <Typography fontFamily={"Roboto-Bold"} fontSize={20}>
                            {item.flatData.name}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <img src={locationIcon} />
                        <Typography color={"#a7945f"} fontSize={14}>
                            {item.flatData.location}
                        </Typography>
                    </div>
                </ContentDiv>
            </Container>
        )
    );
};
export default EventCard;

import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 48px 48px;
    border-radius: 10px;
    background-color: #fff;
`;

const TermsCondition = () => {
    return (
        <Container>
            <Typography fontSize={20} fontFamily={"Roboto-Bold"} mb={"24px"}>Terms & Condition</Typography>
        </Container>
    );
};
export default TermsCondition;

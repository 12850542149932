import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { fetchApi } from "../../../api";
import { ButtonBase, Grid, Typography } from "@mui/material";
import arrowRightWhiteIcon from "../../../assets/images/arrowRight_white.svg";
import { useHistory } from "react-router-dom";
import PromoCard from "../../../components/Card/PromoCard";
import graphicsBackdropPng from "../../../assets/images/Graphics_Backdrop_1.png";
import { useDispatch } from "react-redux";

const PromotionDiv = styled.div`
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-color: #b5a679;
    padding: 96px 100px 48px 100px;
    margin-top: 24px;
    margin-bottom: 48px;
`;

const ViewAllBtn = styled(ButtonBase)`
    && {
        width: 100%;
        min-width: 320px;
        background-color: #313131;
        border-radius: 10px;
        padding: 8px 24px;
        text-transform: none;
    }
`;
const ViewAllDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const FeaturedPromotion = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [items, setItems] = useState([]);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const query =
            "{ queryPromotionsContents { id, flatData { name, type { id, flatData { name } }, describe, applicable { id, flatData { name } }, from, to, logo { url }, image { url }, imageText } } }";
        const result = await fetchApi({ query, dispatch });
        setItems(result.queryPromotionsContents.splice(0, 4));
    };
    return (
        <PromotionDiv imageurl={graphicsBackdropPng}>
            <Grid container justifyContent={"space-between"}>
                <Grid item xs={3}>
                    <Typography fontFamily={"Prata-Regular"} fontSize={48} color={"#fff"}>
                        Featured Promotion
                    </Typography>
                    <Typography color={"#fff"}>
                        Don't miss out on this amazing opportunity to refresh your wardrobe, upgrade
                        your gadgets, or spruce up your home.
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Grid container justifyContent={"flex-end"}>
                        {items.map((card) => (
                            <Grid item xs={5} ml={"12px"} key={card.id}>
                                <PromoCard
                                    item={card}
                                    handleClick={() =>
                                        history.push("/promotion/promotionDetail", {
                                            name: card.flatData.name,
                                        })
                                    }
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent={"flex-end"}>
                <Grid item xs={3}>
                    <ViewAllDiv>
                        <ViewAllBtn onClick={() => history.push("/promotion")}>
                            <img src={arrowRightWhiteIcon} />
                            <Typography color="#fff">View All</Typography>
                        </ViewAllBtn>
                    </ViewAllDiv>
                </Grid>
            </Grid>
        </PromotionDiv>
    );
};

export default FeaturedPromotion;

import Home from "../pages/home";
import Promotion from "../pages/promotion";
import Event from "../pages/event";
import Shop from "../pages/shop";
import ShopDetail from "../pages/shop/shopDetail";
import EventDetail from "../pages/event/eventDetail";
import PromoDetail from "../pages/promotion/promoDetail";
import Profile from "../pages/profile";
export const getDefaultRoute = (role) => {
    return [
        {
            component: Home,
            path: "/",
            name: "home",
            exact: true,
            key: "home",
        },
        {
            component: Home,
            path: "/home",
            name: "home",
            exact: true,
            key: "home",
            crumbs: true,
        },
        {
            component: Promotion,
            path: "/promotion",
            name: "promotion",
            exact: true,
            key: "promotion",
            crumbs: true,
        },
        {
            component: PromoDetail,
            path: "/promotion/promotionDetail",
            name: "promotion",
            exact: true,
            key: "promotion",
            crumbs: true,
        },
        {
            component: Event,
            path: "/event",
            name: "event",
            exact: true,
            key: "event",
            crumbs: true,
        },
        {
            component: EventDetail,
            path: "/event/eventDetail",
            name: "evnetDetail",
            exact: true,
            key: "evnetDetail",
            crumbs: true,
        },
        {
            component: Shop,
            path: "/shop",
            name: "shop",
            exact: true,
            key: "shop",
            crumbs: true,
        },
        {
            component: ShopDetail,
            path: "/shop/shopDetail",
            name: "shopDetail",
            exact: true,
            key: "shopDetail",
            crumbs: true,
        },
        {
            component: Profile,
            path: "/profile",
            name: "profile",
            exact: true,
            key: "profile",
            crumbs: true,
        },
        {
            component: () => <div>404 Not found</div>,
            path: "*",
            name: "404",
            key: "404",
        },
    ];
};

export const getCombineRoute = (menu) => {
    const formattedRoute = [];

    formattedRoute.push(...getDefaultRoute());

    return formattedRoute;
};

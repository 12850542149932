import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

export default function CategorySelect({ handleChange, categoryList, categoryIds }) {
    return (
        categoryList &&
        categoryIds && (
            <FormControl
                sx={{
                    width: "100%",
                    backgroundColor: "#fff",
                    border: "solid 1px #858585",
                }}
            >
                <Select
                    multiple
                    value={categoryIds}
                    onChange={handleChange}
                    renderValue={(selected) =>
                        selected
                            .map((value) => {
                                const selectedCategory = categoryList.find(
                                    (category) => category.id === value,
                                );
                                return selectedCategory ? selectedCategory.flatData.name : "";
                            })
                            .join(", ")
                    }
                    sx={{
                        borderRadius: 0,
                        height: 48,
                    }}
                >
                    {categoryList.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                            <Checkbox checked={categoryIds.indexOf(category.id) > -1} />
                            <ListItemText primary={category.flatData.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    );
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomSwiper from "../home/components/CustomSwiper";
import { Grid, Typography } from "@mui/material";
import { fetchApi } from "../../api";
import { isFromBeforeTo } from "../../utility/utils";
import locationIcon from "../../assets/images/mapMarkerAltWhite.svg";
import { useHistory } from "react-router-dom";
import headerPng from "../../assets/images/header.png";
import { useDispatch } from "react-redux";

const ContainerDiv = styled.div``;

const ImageBackgroundDiv = styled.div`
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-size: cover; // 或其他背景尺寸属性
    padding: 48px 100px;
    margin-top: 2px;
`;
const ImageBackgroundCardDiv = styled.div`
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-size: cover; // 或其他背景尺寸属性
    border-radius: 20px;
    height: 145px;
    cursor: pointer;
`;
const CardContent = styled.div`
    align-self: stretch;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    justify-content: space-between;
`;

const Event = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const query =
            "{ queryEventsContents { id, flatData { name, location, describe, from, to, logo { url }, image { url }, imageText } } }";
        const result = await fetchApi({ query, dispatch });
        classifyItemsByTime(result.queryEventsContents, new Date());
    };

    const classifyItemsByTime = (data, currentDate) => {
        const happeningEvents = [];
        const upcomingEvents = [];

        data.forEach((item) => {
            const from = new Date(item.flatData.from);
            const to = new Date(item.flatData.to);

            if (from <= currentDate && currentDate <= to) {
                happeningEvents.push(item);
            } else {
                upcomingEvents.push(item);
            }
        });
        setState([
            {
                title1: "HAPPENING NOW",
                title2: "Events that are happening now",
                data: happeningEvents,
            },
            {
                title1: "UPCOMING EVENTS",
                title2: "The upcoming events that you can participate",
                data: upcomingEvents,
            },
        ]);
    };

    const DisplayEvents = () => {
        return (
            <>
                {state &&
                    state.map((event, index) => (
                        <React.Fragment key={index}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                                mt={2}
                            >
                                <Grid item xs={4}>
                                    <Typography fontSize={36} fontFamily={"Roboto-Bold"}>
                                        {event.title1}
                                    </Typography>
                                    <Typography color={"#858585"} mb={1}>
                                        {event.title2}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                alignItems={"center"}
                                justifyContent={"flex-start"}
                                spacing={3}
                            >
                                {event.data &&
                                    event.data.map((item) => (
                                        <Grid item xs={4} key={item.id}>
                                            <ImageBackgroundCardDiv
                                                imageurl={item.flatData.image[0].url}
                                                onClick={() =>
                                                    history.push("/event/eventDetail", {
                                                        name: item.flatData.name,
                                                    })
                                                }
                                            >
                                                <CardContent>
                                                    <div>
                                                        <Typography
                                                            ml={2}
                                                            mt={2}
                                                            mb={"12px"}
                                                            fontSize={12}
                                                            color={"#f5f5f5"}
                                                        >
                                                            {isFromBeforeTo(
                                                                item.flatData.from,
                                                                item.flatData.to,
                                                            )}
                                                        </Typography>
                                                        <Typography
                                                            ml={2}
                                                            fontSize={20}
                                                            color={"#f5f5f5"}
                                                            fontFamily={"Roboto-Bold"}
                                                        >
                                                            {item.flatData.name}
                                                        </Typography>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            marginBottom: 15,
                                                            marginLeft: 15,
                                                        }}
                                                    >
                                                        <img src={locationIcon} />
                                                        <Typography fontSize={14} color={"#f5f5f5"}>
                                                            {item.flatData.location}
                                                        </Typography>
                                                    </div>
                                                </CardContent>
                                            </ImageBackgroundCardDiv>
                                        </Grid>
                                    ))}
                            </Grid>
                        </React.Fragment>
                    ))}
            </>
        );
    };

    return (
        <ContainerDiv>
            <ImageBackgroundDiv imageurl={headerPng}>
                <Typography color="#a7945f">
                    Explore, Enjoy with exciting events at Retail Demo
                </Typography>
                <Typography color="#151515" fontSize={40} fontFamily={"Prata-Regular"}>
                    All the Best Deals and Events Under One Roof
                </Typography>
                <Typography color="#151515">Join us for more exciting events</Typography>
            </ImageBackgroundDiv>
            <div style={{ padding: "60px 0px 30px 0px" }}>
                <CustomSwiper />
            </div>
            <div style={{ padding: "0px 100px 48px 100px" }}>
                <DisplayEvents />
            </div>
        </ContainerDiv>
    );
};
export default Event;

import React, { useEffect, useState, useRef } from "react";
import CenteredSwiper from "../../pages/home/components/CenteredSwiper";
import { fetchApi } from "../../api";
import { Button, ButtonBase, Typography } from "@mui/material";
import arrowRightWhiteIcon from "../../assets/images/arrowRight_white.svg";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import CustomNavigationButton from "../CustomNavigationButton";
import { useDispatch } from "react-redux";

const ViewAllBtn = styled(ButtonBase)`
    && {
        background-color: #313131;
        border-radius: 10px;
        padding: 8px 24px;
        text-transform: none;
        width: 25%;
    }
`;
const ViewAllDiv = styled.div`
    padding: 48px 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
const TitleDiv = styled.div`
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Container = styled.div`
    width: 100%;
    height: 100%;
`;

const Recommandation = ({
    isShowTitle = false,
    title,
    query,
    queryName,
    showCardComponent,
    handleClick,
    slidesPerView,
    spaceBetween,
    recommandationStyle,
}) => {
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const swiperRef = useRef(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const result = await fetchApi({ query, dispatch });
        setItems(result[queryName].reverse());
    };

    return (
        <div style={recommandationStyle}>
            {isShowTitle && (
                <TitleDiv>
                    <Typography color={"#a7945f"} fontSize={36} fontFamily={"Prata-Regular"}>
                        RECOMMANDATION
                    </Typography>
                    <Typography color={"#858585"} mb={"24px"}>
                        {title}
                    </Typography>
                </TitleDiv>
            )}
            <Swiper
                ref={swiperRef}
                slidesPerView={slidesPerView}
                spaceBetween={spaceBetween}
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                    disabledClass: "disable", // 当导航
                }}
                modules={[Navigation]}
            >
                {items.map((item) => (
                    <SwiperSlide key={item.id}>
                        <Container>{showCardComponent(item)}</Container>
                    </SwiperSlide>
                ))}
                <div className="swiper-button-prev">
                    <CustomNavigationButton direction="prev" />
                </div>
                <div className="swiper-button-next">
                    <CustomNavigationButton direction="next" />
                </div>
            </Swiper>
            <ViewAllDiv>
                <ViewAllBtn onClick={handleClick}>
                    <img src={arrowRightWhiteIcon} />
                    <Typography color="#fff">View All</Typography>
                </ViewAllBtn>
            </ViewAllDiv>
        </div>
    );
};
export default Recommandation;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CustomSwiper from "./components/CustomSwiper";
import Recommandation from "../../components/Recommandation";
import ShopCard from "../../components/Card/ShopCard";
import { useHistory } from "react-router-dom";
import backgroundPng from "../../assets/images/background.png";
import Statistic from "./components/Statistic";
import DiscoverOurShops from "./components/DiscoverOurShops";
import FeaturedPromotion from "./components/FeaturedPromotion";

const Container = styled.div``;

const ImageBackgroundDivForSwiper = styled.div`
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-size: cover; // 或其他背景尺寸属性
    padding: 60px 0px;
`;

const Home = () => {
    const history = useHistory();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // 定义一个函数来更新 windowWidth 的值
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // 在组件挂载时添加 resize 事件监听器
        window.addEventListener("resize", handleResize);

        // 在组件卸载时移除 resize 事件监听器
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // 空数组作为依赖项，表示只在组件挂载时执行一次
    return (
        <Container>
            <ImageBackgroundDivForSwiper imageurl={backgroundPng}>
                <CustomSwiper />
            </ImageBackgroundDivForSwiper>
            <FeaturedPromotion />
            <DiscoverOurShops />
            <Statistic />
            <Recommandation
                query={
                    "{ queryShopsContents { id, flatData { name, category { id, flatData { name } }, title, describe, location, openingHours, enquirues, email, imageText, image { url } } } }"
                }
                queryName={"queryShopsContents"}
                showCardComponent={(item) => (
                    <ShopCard
                        item={item}
                        handleClick={() =>
                            history.push("/shop/shopDetail", { name: item.flatData.name })
                        }
                        shopCardStyle={{ marginLeft: 100 }}
                    />
                )}
                handleClick={() => history.push("/shop")}
                slidesPerView={windowWidth > 1445 ? 3 : 2.5}
                spaceBetween={24}
                recommandationStyle={{ marginTop: 24 }}
            />
        </Container>
    );
};
export default Home;

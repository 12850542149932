import React from "react";
import styled, { css } from "styled-components";
import logoPic from "../../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import searchSvg from "../../assets/images/search.svg";
import globeSvg from "../../assets/images/globe.svg";
import profileSvg from "../../assets/images/Icon_Profile.svg";
import profileActiveSvg from "../../assets/images/Icon_ProfileActive.svg";
import { Grid, InputBase, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 22.5px 0;
    justify-content: space-between;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
`;
const RouterDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const RouteItemDiv = styled(Link)`
    color: #151515;
    font-size: 16;
    padding: 0 43.5px;
    text-decoration: none;
    ${(props) =>
        props.selected &&
        css`
            font-family: "Roboto-Medium";
            color: #a7945f;
        `}
`;
const SearchDiv = styled.div`
    border-radius: 100px;
    background-color: #f5f5f5;
    padding: 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
`;

const RouterList = [
    { name: "Home", path: "home" },
    { name: "Shops", path: "shop" },
    { name: "Events", path: "event" },
    { name: "Promotions", path: "promotion" },
];

const RouteItem = ({ route }) => {
    const location = useLocation();
    return (
        <RouteItemDiv
            to={`/${route.path}`}
            selected={location.pathname.split("/")[1] === route.path}
        >
            <Typography>{route.name}</Typography>
        </RouteItemDiv>
    );
};

const Search = () => {
    return (
        <SearchDiv>
            <img src={searchSvg} />
            <InputBase
                placeholder="Search content here"
                inputProps={{ "aria-label": "Search content here" }}
                sx={{ color: "#151515", fontSize: 14 }}
            />
        </SearchDiv>
    );
};
const Header = () => {
    const history = useHistory();
    const location = useLocation();
    return (
        <Container>
            <Grid container alignItems={"center"} spacing={2} flexWrap={"nowrap"}>
                <Grid item xs={1}></Grid>
                <Grid item xs={3}>
                    <img src={logoPic} style={{ height: 40 }} />
                </Grid>
                <Grid item xs={5}>
                    <RouterDiv>
                        {RouterList.map((route, index) => (
                            <RouteItem key={index} route={route} />
                        ))}
                    </RouterDiv>
                </Grid>
                <Grid item>
                    <Search />
                </Grid>
                <Grid item style={{ cursor: "pointer" }}>
                    <img src={globeSvg} />
                </Grid>
                <Grid item style={{ cursor: "pointer" }}>
                    <img
                        src={
                            location.pathname.split("/")[1] === "profile"
                                ? profileActiveSvg
                                : profileSvg
                        }
                        onClick={() => {
                            history.push("/profile");
                        }}
                    />
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </Container>
    );
};
export default Header;

import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 12px;
`;

const InfoCard = ({ renderInfoItem }) => {
    return (
        <Container>
            <Item>
                <Typography  fontSize={20} fontFamily={'Roboto-Bold'}>
                    Information
                </Typography>
            </Item>
            {renderInfoItem &&
                renderInfoItem.map((item, index) => (
                    <Item key={index}>
                        <img src={item.imageUrl} />
                        <div>
                            <Typography color={"#a7945f"} fontSize={14}>
                                {item.title}
                            </Typography>
                            <Typography color={"#858585"} fontSize={12}>
                                {item.value || "-"}
                            </Typography>
                        </div>
                    </Item>
                ))}
        </Container>
    );
};
export default InfoCard;

import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import styled from "styled-components";

const Button = styled.button`
    width: 100%;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    color: #151515;
    background-color: #fff;
    background-color: ${(props) => props.isActive && "#857549"};
    color: ${(props) => props.isActive && "#f5f5f5"};
`;

const _Paper = styled(Paper)`
    padding: 10px 12px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 15px;
`;

const List = [
    { title: "Latest" },
    { title: "Ascending Order (A - Z)" },
    { title: "Descending Order (Z - A)" },
];

export default function CustomPopper({
    placement = "bottom-end",
    open,
    anchorEl,
    sortType,
    handleClick,
}) {
    const ListItem = ({ item }) => {
        return (
            <Button isActive={sortType === item.title} onClick={handleClick(item.title)}>
                <Typography fontSize={16}>{item.title}</Typography>
            </Button>
        );
    };

    return (
        <Box sx={{ width: 500 }}>
            <Popper
                // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
                sx={{ zIndex: 1200 }}
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <_Paper>
                            {List.map((item, index) => (
                                <ListItem key={index} item={item} />
                            ))}
                        </_Paper>
                    </Fade>
                )}
            </Popper>
        </Box>
    );
}

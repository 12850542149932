import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { fetchApi } from "../../../api";
import { Typography } from "@mui/material";
import arrowRightWhiteIcon from "../../../assets/images/arrowRight_white.svg";
import arrowRightGoldenIcon from "../../../assets/images/arrowRight_Golden.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const StatisticDiv = styled.div`
    display: flex;
    flex-direction: row;
    z-index: 10;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 0 100px;
    margin-top: 24px;
`;

const StatisticCard = styled.div`
    width: 20%;
    height: 120px;
    padding: 0px 24px;
    background-color: ${(props) => props.backgrondcolor || "#fff"};
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
`;
const StatisticCardNext = styled.div`
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #a7945f;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: ${(props) => props.bordercolor};
`;

const Statistic = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState([]);

    useEffect(() => {
        fetchShopData();
    }, []);

    const countObjectsWithName = (arr, name) => {
        const filteredObjects = arr.filter((item) => {
            return item.flatData.category && item.flatData.category[0].flatData.name === name;
        });
        return filteredObjects.length;
    };
    const getIdsByName = (arr, name) => {
        return arr.reduce((result, item) => {
            if (item.flatData.name && item.flatData.name === name) {
                result.push(item.id);
            }
            return result;
        }, []);
    };

    const fetchShopData = async () => {
        let shopQuery =
            "{ queryShopsContents { id, flatData { name, category { id, flatData { name } }, title, describe, location, openingHours, enquirues, email, imageText, image { url } } } }";
        let shopResult = await fetchApi({ query: shopQuery, dispatch });
        let categoryQuery = `{ queryCategoriesContents { id, flatData { name } } }`;
        let categoryResult = await fetchApi({ query: categoryQuery, dispatch });
        setState([
            {
                ids: getIdsByName(categoryResult.queryCategoriesContents, "Dinning"),
                value: countObjectsWithName(shopResult.queryShopsContents, "Dinning"),
                label: "Dinning",
            },
            {
                ids: getIdsByName(categoryResult.queryCategoriesContents, "Fashion & Accessories"),
                value: countObjectsWithName(shopResult.queryShopsContents, "Fashion & Accessories"),
                label: "Fashion & Accessories",
            },
            {
                ids: getIdsByName(categoryResult.queryCategoriesContents, "Electronics"),
                value: countObjectsWithName(shopResult.queryShopsContents, "Electronics"),
                label: "Electronics",
            },
        ]);
    };

    return (
        <StatisticDiv>
            {state.map((item, index) => (
                <StatisticCard
                    key={index}
                    onClick={() => history.push("/shop", { filter: item.ids })}
                >
                    <Typography fontFamily={"Roboto-Bold"} fontSize={48}>
                        {item.value}
                    </Typography>
                    <Typography color={"#858585"}>{item.label}</Typography>
                    <StatisticCardNext>
                        <img src={arrowRightGoldenIcon} />
                    </StatisticCardNext>
                </StatisticCard>
            ))}
            <StatisticCard backgrondcolor="#313131" onClick={() => history.push("/shop")}>
                <Typography color={"#f5f5f5"} fontSize={24} fontFamily={"Roboto-Bold"}>
                    {"Explore More"}
                </Typography>
                <StatisticCardNext bordercolor="#fff">
                    <img src={arrowRightWhiteIcon} />
                </StatisticCardNext>
            </StatisticCard>
        </StatisticDiv>
    );
};

export default Statistic;
import { createGlobalStyle } from "styled-components";
import { createTheme } from "@mui/material/styles";

let theme = createTheme({
    typography: {
        fontFamily: "Roboto-Regular",
        fontSize: 16,
        lineHeight: 1.67,
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                "@font-face": "Roboto-Bold",
            },
        },
    },
    palette: {
        text: {
            primary: "#151515",
            secondary: "#f5f5f5",
            third: "#a7945f",
        },
    },
});

let GlobalStyle = createGlobalStyle`
    body{
        margin: 0;
        padding: 0;
        color:${theme.palette.text.primary}
    }

`;

export { theme, GlobalStyle };

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { fetchApi } from "../../api";
import { ButtonBase, Grid, Typography } from "@mui/material";
import locationIcon from "../../assets/images/mapMarkerAlt.svg";
import dateIcon from "../../assets/images/calendarAlt.svg";
import { isFromBeforeTo } from "../../utility/utils";
import EventCard from "../../components/Card/EventCard";
import Recommandation from "../../components/Recommandation";
import { useDispatch } from "react-redux";

const ImageBackgroundDiv = styled.div`
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-size: cover; // 或其他背景尺寸属性
    height: 600px;
`;
const ImageBackgroundContentDiv = styled.div`
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: flex-end;
`;
const Container = styled.div``;
const DetailDiv = styled.div`
    background-color: #fff;
    padding: 48px 100px;
`;
const BackBtn = styled(ButtonBase)`
    && {
        text-transform: none;
        text-align: left;
    }
`;
const LabelItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const EventDetail = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const name = location.state.name;
    const [detail, setDetail] = useState({});

    useEffect(() => {
        fetchData();
    }, [name]);

    const fetchData = async () => {
        const query = `{ queryEventsContents (filter: "data/Name/iv eq '${encodeURIComponent(
            name,
        )}'") { id, flatData { name, location, describe, from, to, logo { url }, image { url }, imageText } } }`;
        const result = (await fetchApi({ query, dispatch })) || {};
        setDetail(result.queryEventsContents[0].flatData);
    };

    return (
        detail.image && (
            <Container>
                <ImageBackgroundDiv imageurl={detail.image[0].url}>
                    <ImageBackgroundContentDiv>
                        <Typography
                            color={"#fff"}
                            fontFamily={"Roboto-Bold"}
                            fontSize={60}
                            ml={5}
                            mb={2}
                        >
                            {detail.imageText}
                        </Typography>
                    </ImageBackgroundContentDiv>
                </ImageBackgroundDiv>
                <DetailDiv>
                    <Typography fontSize={48} fontFamily={"Roboto-Bold"} mb={"36px"}>
                        {detail.name}
                    </Typography>
                    <Grid container mb={"36px"}>
                        <Grid item xs={6}>
                            <LabelItem>
                                <img src={locationIcon} />
                                <div>
                                    <Typography color={"#a7945f"} fontSize={14}>
                                        Location
                                    </Typography>
                                    <Typography color={"#858585"} fontSize={12}>
                                        {detail.type || "-"}
                                    </Typography>
                                </div>
                            </LabelItem>
                        </Grid>
                        <Grid item xs={6}>
                            <LabelItem>
                                <img src={dateIcon} />
                                <div>
                                    <Typography color={"#a7945f"} fontSize={14}>
                                        Date
                                    </Typography>
                                    <Typography color={"#858585"} fontSize={12}>
                                        {isFromBeforeTo(detail.from, detail.to)}
                                    </Typography>
                                </div>
                            </LabelItem>
                        </Grid>
                    </Grid>
                    <Typography>{detail.describe || "-"}</Typography>
                </DetailDiv>
                <Recommandation
                    isShowTitle
                    title={"Events that you may also like"}
                    query={
                        "{ queryEventsContents { id, flatData { name, location, describe, from, to, logo { url }, image { url }, imageText } } }"
                    }
                    queryName={"queryEventsContents"}
                    showCardComponent={(item) => (
                        <EventCard
                            item={item}
                            handleClick={() => {
                                history.push("/event/eventDetail", { name: item.flatData.name });
                            }}
                            eventCardStyle={{ marginLeft: 100 }}
                        />
                    )}
                    handleClick={() => history.push("/event")}
                    slidesPerView={4}
                    spaceBetween={24}
                />
            </Container>
        )
    );
};
export default EventDetail;

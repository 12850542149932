import {
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import avatar from "../../../assets/images/Avatar.png";
import saveIcon from "../../../assets/images/save.svg";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 48px 48px;
    border-radius: 10px;
    background-color: #fff;
`;
const Input = styled(TextField)`
    & .MuiFilledInput-root {
        background-color: #f5f5f5;
        min-height: 48px;
        font-size: 16px;
        font-family: "Roboto-Regular";
        padding-bottom: ${(props) => props.pb};

        /* 设置滚动条的宽度和颜色 */
        ::-webkit-scrollbar {
            width: 2px;
            background-color: #f5f5f5;
        }

        /* 设置滚动条滑块的样式 */
        ::-webkit-scrollbar-thumb {
            background-color: #858585;
            border-radius: 5px;
        }

        /* 设置滚动条鼠标悬停时的样式 */
        ::-webkit-scrollbar-thumb:hover {
            background-color: #9575cd;
        }
    }
    & .css-c5v1qu-MuiInputBase-input-MuiFilledInput-input {
        padding: 0;
        padding-left: 12px;
    }
    & .css-1n7v3u8-MuiInputBase-root-MuiFilledInput-root::after {
        border-bottom: 2px solid #a7945f;
    }
`;
const PersonalInformation = ({ state }) => {
    return (
        <Container>
            <Typography fontSize={20} fontFamily={"Roboto-Bold"} mb={"24px"}>
                {"Personal Information"}
            </Typography>
            <Grid container justifyContent={"space-between"} alignItems={"flex-start"} mb={"24px"}>
                <Grid item xs={3}>
                    <img src={avatar} style={{ width: 100, height: 100 }} />
                </Grid>
                <Grid item xs={3} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <img src={saveIcon} />
                    <Typography color={"#a7945f"} ml={"9px"}>
                        Update Information
                    </Typography>
                </Grid>
            </Grid>
            <Grid container mb={"24px"}>
                <Grid item xs={6}>
                    <InputLabel shrink htmlFor="firstName">
                        <Typography color={"#857549"}>First Name</Typography>
                    </InputLabel>
                    <Input
                        variant="filled"
                        size="small"
                        fullWidth
                        id="firstName"
                        value={state.firstName}
                    />
                </Grid>
            </Grid>
            <Grid container mb={"24px"}>
                <Grid item xs={6}>
                    <InputLabel shrink htmlFor="lastName">
                        <Typography color={"#857549"}>Last Name</Typography>
                    </InputLabel>
                    <Input
                        variant="filled"
                        size="small"
                        fullWidth
                        id="lastName"
                        value={state.lastName}
                    />
                </Grid>
            </Grid>
            <Grid container mb={"24px"}>
                <Grid item xs={6}>
                    <InputLabel shrink htmlFor="phoneNumber">
                        <Typography color={"#857549"}>Phone Number</Typography>
                    </InputLabel>
                    <Input
                        variant="filled"
                        size="small"
                        fullWidth
                        id="phoneNumber"
                        value={state.phoneNumber}
                    />
                </Grid>
            </Grid>
            <Grid container mb={"24px"}>
                <Grid item xs={6}>
                    <InputLabel shrink htmlFor="email">
                        <Typography color={"#857549"}>Email</Typography>
                    </InputLabel>
                    <Input variant="filled" size="small" fullWidth id="email" value={state.email} />
                </Grid>
            </Grid>
            <Grid container mb={"24px"}>
                <Grid item xs={6}>
                    <InputLabel shrink htmlFor="biography">
                        <Typography color={"#857549"}>Biography</Typography>
                    </InputLabel>
                    <FormControl fullWidth>
                        <div style={{ position: "relative" }}>
                            <Input
                                variant="filled"
                                size="small"
                                fullWidth
                                id="biography"
                                maxRows={3}
                                multiline
                                value={state.describe}
                                pb={"30px"}
                                inputProps={{ maxLength: 500 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            style={{ position: "absolute", bottom: 15, right: 20 }}
                                        >
                                            <Typography fontSize={12}>
                                                {state.describe?.length}/{500}
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </FormControl>
                </Grid>
            </Grid>
        </Container>
    );
};
export default PersonalInformation;

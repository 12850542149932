import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { fetchApi } from "../../../api";
import { Typography } from "@mui/material";
import locationIcon from "../../../assets/images/mapMarkerAltWhite.svg";
import clockIcon from "../../../assets/images/clockWhite.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const DiscoverShopsDiv = styled.div`
    padding: 0 100px;
`;
const ImageBackgroundDiv = styled.div`
    background-image: ${(props) => `url('${props.imageurl}')`};
    background-size: cover; // 或其他背景尺寸属性
    height: 600px;
    border-radius: 20px;
    margin-top: 24px;
    cursor: pointer;
`;
const FeaturedShopContent = styled.div`
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    position: relative;
`;
const TipDiv = styled.div`
    position: absolute;
    padding: 8px 61.5px;
    top: 48px;
    right: 0;
    background-color: #857549;
    border-radius: 10px 0 0 10px;
`;
const BubbleDiv = styled.div`
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 8px 12px;
    border-radius: 100px;
    border: solid 1px #f5f5f5;
`;
const FeaturedShopBottomDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const DiscoverOurShops = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [shop, setShop] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const query =
            "{ queryShopsContents { id, flatData { name, category { id, flatData { name } }, title, describe, location, openingHours, enquirues, email, imageText, image { url } } } }";
        const result = await fetchApi({ query, dispatch });
        setShop(result.queryShopsContents[0]);
    };

    return (
        shop.flatData && (
            <DiscoverShopsDiv>
                <Typography color="#a7945f">Explore & Discover</Typography>
                <Typography color="#151515" fontSize={40} fontFamily={"Prata-Regular"}>
                    Discover our Shops
                </Typography>
                <Typography color="#151515">
                    Renowned brands from all over the world are brought under one roof.
                </Typography>
                <ImageBackgroundDiv
                    imageurl={shop.flatData.image && shop.flatData.image[0].url}
                    onClick={() => {
                        history.push("/shop/shopDetail", { name: shop.flatData.name });
                    }}
                >
                    <FeaturedShopContent>
                        <TipDiv>
                            <Typography color="#f5f5f5" fontSize={18} fontFamily={"Roboto-Medium"}>
                                Featured Shop
                            </Typography>
                        </TipDiv>
                        <div style={{ padding: 48 }}>
                            <BubbleDiv>
                                <Typography color={"#f5f5f5"} fontSize={12}>
                                    {shop.flatData?.category[0].flatData.name}
                                </Typography>
                            </BubbleDiv>
                            <FeaturedShopBottomDiv>
                                <div>
                                    <Typography
                                        color={"#f5f5f5"}
                                        fontFamily={"Roboto-Bold"}
                                        fontSize={60}
                                    >
                                        {shop.flatData?.name}
                                    </Typography>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img src={locationIcon} />
                                        <Typography
                                            color={"#f5f5f5"}
                                            fontFamily={"Roboto-Medium"}
                                            fontSize={18}
                                        >
                                            {shop.flatData?.location}
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-start",
                                        width: "22%",
                                    }}
                                >
                                    <img src={clockIcon} />
                                    <div>
                                        <Typography color={"#f5f5f5"} fontSize={14}>
                                            Opening Hours
                                        </Typography>
                                        <Typography
                                            color={"#f5f5f5"}
                                            fontSize={18}
                                            fontFamily={"Roboto-Medium"}
                                        >
                                            {shop.flatData?.openingHours}
                                        </Typography>
                                    </div>
                                </div>
                            </FeaturedShopBottomDiv>
                        </div>
                    </FeaturedShopContent>
                </ImageBackgroundDiv>
            </DiscoverShopsDiv>
        )
    );
};

export default DiscoverOurShops;
